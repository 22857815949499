import {
  isNonDetect,
  getFormattedValue,
  getGuidelineValue,
  convertValueByUnits,
} from './result.js';
import { hasFooterSettingToggled } from '../modules/project/data-table/components/table/editable-footer/types/block-editor';
import { EditableBlockType } from '@component-library/enviro-table-footer-blocks';
import { checkHydrocarbonSettings } from '@/js/modules/project/data-table/composables/useEnviroScenarioManager';
import { CriteriaType } from '@component-library/enviro';

const basisTypes = {
  human_health: {
    default: 'Human Health',
    AU: 'H/H',
  },
  ecological: {
    default: 'Ecological',
    AU: 'Eco',
  },
  environmental: {
    default: 'Environmental Criteria',
  },
  health_and_ecological: {
    default: 'Human Health & Ecological',
  },
  livestock_drinking_water_protection: {
    default: 'Livestock Drinking Water Protection',
  },
  direct_human_health: {
    default: 'Direct Human Health',
  },
  aquatic_habitat_goal_levels: {
    default: 'Aquatic Habitat Goal Levels',
  },
  terrestial_habitat_levels: {
    default: 'Terrestrial Habitat Levels',
  },
  leaching_to_gw: {
    default: 'Leaching To Groundwater',
  },
  gross_contamination: {
    default: 'Gross Contamination',
  },
  odour_nusiance_levels: {
    default: 'Odour Nuisance Levels',
  },
  tier_1: {
    default: 'Tier 1',
  },
  waste: {
    default: 'Waste Acceptance',
  },
  vapor_intrusion_human_health: {
    default: 'Groundwater Vapor Intrusion Human Health',
  },
};

const landuseTypes = {
  residential: {
    default: 'Residential',
    'nepm-hils': 'HIL A (Residential with garden/access)',
    'nepm-hsl': 'HSL A (Residential with garden/access)',
    'nepm-eils': 'Urban Residential & Public Open Space',
    'nepm-esls': 'Urban Residential & Public Open Space',
    'nepm-management-limits': 'Residential, parkland and public open space',
    'pfas-nemp-soil': 'HIL A (Residential with garden/accessible soil)',
    'ca-ccme-soil': 'Residential / Park',
  },
  industrial: {
    default: 'Commercial / Industrial',
    'nepm-hils': 'HIL D (Commercial / Industrial)',
    'nepm-hsl': 'HSL D (Commercial / Industrial)',
    'pfas-nemp-soil': 'HIL D (Commercial / Industrial)',
    'ca-ccme-soil': 'Industrial',
  },
  recreational: {
    default: 'Recreational',
    'nepm-hils': 'HIL C (Public space)',
    'nepm-hsl': 'HSL C (Recreational / Open Space)',
    'pfas-nemp-soil': 'HIL C (Public open space)',
  },
  'res-and-high-res': {
    default: 'Residential and High Density Residential',
    'nepm-hsl': 'HSL A & B (Low - High Density Residential)',
  },
  'high-density-residential': {
    default: 'High Density Residential',
    'nepm-hils': 'HIL B (Residential with limited soil access)',
    'nepm-hsl': 'HSL B (Residential with limited soil access)',
    'pfas-nemp-soil':
      'HIL B (Residential with minimal opportunities for soil access)',
  },
  'direct-exposure': {
    'pfas-nemp-soil': 'Ecological direct exposure',
  },
  'indirect-exposure': {
    'pfas-nemp-soil': 'Ecological indirect exposure',
  },
  'groundwater-protection': {
    default: 'Groundwater Protection',
  },
  'lifestyle-block': {
    default: 'Lifestyle Block',
  },
  agricultural: {
    default: 'Agricultural',
  },
  worker: {
    default: 'Worker (US Only)',
  },
  'freshwater-99': {
    default: 'Freshwater 99%',
  },
  'freshwater-95': {
    default: 'Freshwater 95%',
  },
  'freshwater-90': {
    default: 'Freshwater 90%',
  },
  'freshwater-80': {
    default: 'Freshwater 80%',
  },
  'freshwater-unknown': {
    default: 'Freshwater (Unknown Reliability)',
  },
  'marinewater-99': {
    default: 'Marine Water 99%',
  },
  'marinewater-95': {
    default: 'Marine Water 95%',
  },
  'marinewater-90': {
    default: 'Marine Water 90%',
  },
  'marinewater-80': {
    default: 'Marine Water 80%',
  },
  'marinewater-unknown': {
    default: 'Marine Water (Unknown Reliability)',
  },
  'drinking-water': {
    default: 'Drinking Water',
  },
  'non-drinking-water': {
    default: 'Non-Drinking Water',
  },
  irrigation: {
    default: 'Irrigation',
  },
  'potable-water': {
    default: 'Potable Water',
  },
  'stock-water': {
    default: 'Stock Water',
  },
  'areas-of-ecological-significance': {
    default: 'Areas of Ecological Significance',
  },
  'ecological-receptors': {
    default: 'Ecological Receptors',
  },
  'res-and-high-density': {
    default: 'Res A / B',
  },
  'national-park': {
    default: 'National park',
  },
  'absolute-max': {
    default: 'Absolute Max',
  },
  'max-average': {
    default: 'Max Average',
  },
  'category-one': {
    default: 'General Solid Waste CT1',
  },
  'category-two': {
    default: 'Restricted Solid Waste CT2',
  },
  'tclp-one': {
    default: 'General Solid Waste TCLP1',
  },
  'tclp-two': {
    default: 'Restricted Solid Waste TCLP2',
  },
  'scc-one': {
    default: 'General Solid Waste SCC1',
  },
  'scc-two': {
    default: 'Restricted Solid Waste SCC2',
  },
  'fill-material': {
    default: 'Fill Material',
  },
  'category-b-tc': {
    default: 'Category B - TC',
  },
  'category-c-tc': {
    default: 'Category C - TC',
  },
  'category-d-tc': {
    default: 'Category D - TC',
  },
  'category-b-leachable': {
    default: 'Category B - Leachable Concentration',
  },
  'category-c-leachable': {
    default: 'Category C - Leachable Concentration',
  },
  'category-d-leachable': {
    default: 'Category D - Leachable Concentration',
  },
  'class-a-screening': {
    default: 'Class A - Screening Criteria',
  },
  'class-b-screening': {
    default: 'Class B - Screening Criteria',
  },
  'class-a-leachate': {
    default: 'Class A - Concentration In Leachate',
  },
  'class-b-leachate': {
    default: 'Class B - Concentration In Leachate',
  },
  'aup (volcanic)': {
    default: 'AUP - Volcanic',
  },
  'aup (non-volcanic)': {
    default: 'AUP - Non-Volcanic',
  },
  'aup (eco-sgv)': {
    default: 'AUP - Eco-SGV',
  },
  dgv: {
    default: 'DGV',
  },
  'gv-high': {
    default: 'GV-high',
  },
  any: {
    default: 'Any',
  },
  all: {
    default: 'All',
  },
  All: {
    default: 'Intrusive Maintenance Worker',
  },
  'residential-aged': {
    default: 'Residential Aged',
  },
  'residential-fresh': {
    default: 'Residential Fresh',
  },
  'industrial-aged': {
    default: 'Industrial Aged',
  },
  'industrial-fresh': {
    default: 'Industrial Fresh',
  },
  'irrigation-short-term': {
    default: 'Irrigation Short Term',
  },
  'irrigation-long-term': {
    default: 'Irrigation Long Term',
  },
  'livestock-drinking-water': {
    default: 'Livestock Drinking Water',
  },
  human: {
    default: 'Human Health',
  },
  health: {
    default: 'Human Health',
  },
  aesthetic: {
    default: 'Aesthetic',
  },
  'livestock-drinking-water-protection': {
    default: 'Livestock Drinking Water Protection',
  },
  'national-park-fresh': {
    default: 'National Park Fresh',
  },
  'national-park-aged': {
    default: 'National Park Aged',
  },
  'recreational-human': {
    default: 'Recreational Human',
  },
  'recreational-aesthetic': {
    default: 'Recreational Aesthetic',
  },
  tapwater: {
    default: 'Tapwater',
  },
  commercial: {
    default: 'Commerical / Industrial',
    'ca-ccme-soil': 'Commercial',
  },
  freshwater: {
    default: 'Fresh Waters',
  },
  marinewater: {
    default: 'Marine Waters',
  },
  'low-density res': {
    default: 'HSL-A (Low-Density Residential)',
  },
  'high-density res': {
    default: 'HSL-B (High-Density Residential)',
  },
  'rec-open-space': {
    default: 'HSL-C (Recreational/Open Space)',
  },
  'commercial-industrial': {
    default: 'HSL-D (Commercial/Industrial)',
  },
  'shallow-trench': {
    default: 'Intrusive Maintenance Worker (Shallow Trench)',
  },
  'intrusive-worker': {
    default: 'Intrusive Maintenance Worker',
  },
  marine: {
    default: 'Marine',
  },
};

const formattedTypes = {
  cr: {
    default: 'Cancer Risk',
  },
  ncr: {
    default: 'Non-Cancer Risk',
  },
  conservative: {
    default: 'Conservative',
  },
  minimally_vegetated_area: {
    default: 'Minimally Vegetated Area',
  },
  significant_vegetated_area: {
    default: 'Significant Vegetated Area',
  },
  non_drinking_water: {
    default: 'Non-Drinking Water',
  },
  drinking_water: {
    default: 'Drinking Water',
  },
  freshwater_ecotox: {
    default: 'Fresh Water Ecotox',
  },
  saltwater_ecotox: {
    default: 'Saltwater Ecotox',
  },
  seafood_ingestion_human_health: {
    default: 'Seafood Ingestion Human Health',
  },
  'short-term': {
    default: 'Short Term',
  },
  'long-term': {
    default: 'Long Term',
  },
  isqg: {
    default: 'ISQG',
  },
  pel: {
    default: 'PEL',
  },
  'coarse soil': {
    default: 'Coarse',
  },
  'fine soil': {
    default: 'Fine',
  },
};

const formattedPathways = {
  all_pathways: {
    default: 'All Pathways',
  },
  significant_vegetated_area: {
    default: 'Significant Vegetated Area',
  },
  minimally_vegetated_area: {
    default: 'Minimally Vegetated Area',
  },
  deep_greater_than_10: {
    default: 'Depth Greater Than 10m',
  },
  shallow_less_than_10: {
    default: 'Depth Less Than 10m',
  },
  groundwater_2m: {
    default: 'Depth To Groundwater: 2m',
  },
  groundwater_4m: {
    default: 'Depth To Groundwater: 4m',
  },
  groundwater_8m: {
    default: 'Depth To Groundwater: 8m',
  },
  'inhalation of indoor air': {
    default: 'Inhalation of indoor air',
  },
  'inhalation of outdoor air': {
    default: 'Inhalation of outdoor air',
  },
  'vapour intrusion': {
    default: 'Vapour Intrusion',
  },
};

const formattedHQTypes = {
  'hq-1.0': {
    default: 'HQ 1.0',
  },
  'hq-0.1': {
    default: 'HQ 0.1',
  },
};

const mediaTypes = {
  silty_clay: 'Silty Clay',
  sandy_silt: 'Sandy Silt',
  peats_and_highly_organic_soils: 'Peats & Highly Organic Soils',
  highly_organic_soils: 'Peats & Highly Organic Soils',
  conservative: 'Conservative Of All',
  'coarse-fine': 'Coarse / Fine',
};

export const getResultStyling = (result, scenarioSet, verificationType) => {
  const getGeneralCellStyleProperties = () => {
    return scenarioSet && scenarioSet.general_cell_styling
      ? getEnviroCellStyleProperties(scenarioSet.general_cell_styling)
      : {};
  };

  if (!result) {
    return getGeneralCellStyleProperties();
  }

  if (scenarioSet && scenarioSet.nd_cell_styling && isNonDetect(result)) {
    return getEnviroCellStyleProperties(scenarioSet.nd_cell_styling);
  }

  if (
    scenarioSet &&
    scenarioSet.above_nd_cell_styling &&
    !isNonDetect(result) &&
    verificationType === 'blanks'
  ) {
    return getEnviroCellStyleProperties(scenarioSet.above_nd_cell_styling);
  }

  return getGeneralCellStyleProperties();
};

export const getEnviroCellStyleProperties = (properties) => {
  if (!properties) {
    return null;
  }

  let stylingOptions = {};

  if (properties.background_color) {
    stylingOptions['background-color'] = properties.background_color;
  }

  if (properties.text_color) {
    stylingOptions.color = properties.text_color;
  }

  if (properties.border_color) {
    stylingOptions.border = `1px solid ${properties.border_color} !important`;
  }

  if (properties.is_bold) {
    stylingOptions['font-weight'] = 'bold';
  }

  if (properties.is_italic) {
    stylingOptions['font-style'] = 'italic';
  }

  if (properties.is_underlined) {
    stylingOptions['text-decoration'] = 'underline';
  }

  return stylingOptions;
};

/**
 * @deprecated use useScenarioStyling composable -> getScenarioStylingProperties
 */
export const getStylingPropertiesByMethod = (scenario) => {
  if (!scenario || !scenario.exceedance_cell_styling) {
    return getDefaultPropertiesByType();
  }

  const stylingOptions = getEnviroCellStyleProperties(
    scenario.exceedance_cell_styling
  );

  if (Object.keys(stylingOptions).length == 0) {
    return getDefaultPropertiesByType();
  }

  return stylingOptions;
};

/**
 * @deprecated use useScenarioStyling
 */
export const getStylingPropertiesByMethodAsString = (style) => {
  let properties = {};

  if (!style) {
    properties = getDefaultPropertiesByType();
  } else {
    properties = getEnviroCellStyleProperties(style);
  }

  let styleString = '';
  Object.keys(properties).forEach((property) => {
    styleString += `${property}: ${properties[property]};`;
  });

  return styleString;
};

export const getDefaultPropertiesByType = () => {
  return {
    'background-color': '#f8d7da',
  };
};

export const getDefaultScenarioSetStyling = (set) => {
  if (!set) {
    set = {};
  }

  if (!set.general_cell_styling) {
    set.general_cell_styling = {
      text_color: '#000000',
    };
  }

  if (!set.nd_cell_styling) {
    set.nd_cell_styling = {
      text_color: '#000000',
    };
  }

  if (!set.above_nd_cell_styling) {
    set.above_nd_cell_styling = {};
  }

  if (!set.nd_exceedance_cell_styling) {
    set.nd_exceedance_cell_styling = {
      background_color: '#f8f1d7',
    };
    return set;
  }

  if (!set.duplicate_exceedance_cell_style_id) {
    set.duplicate_exceedance_cell_styling = {
      background_color: '#f8d7da',
    };
  }

  if (!set.triplicate_exceedance_cell_style_id) {
    set.triplicate_exceedance_cell_styling = {
      background_color: '#f8d7da',
    };
  }

  return set;
};

/**
 * @deprecated use useScenarioStyling composable
 */
export const getStyleByScenario = (set, scenario) => {
  if (!set) {
    return;
  }

  const styles = set.styles;

  let style = null;
  if (scenario.criteria_type == 'landuse') {
    style = styles.find(
      (s) =>
        s.scenario_id == scenario.scenario_id && s.factor == scenario.factor
    );
  }

  if (scenario.criteria_type == 'criteria') {
    style = styles.find((s) => s.criteria_set_id == scenario.criteria_set_id);
  }

  // if styling by document
  if (style && scenario.document_id) {
    const short_url = scenario.document.short_url;
    if (!short_url) {
      return null;
    }

    const documentStyle = style.documents.find(
      (d) =>
        d.short_url == short_url &&
        checkHydrocarbonSettings(
          scenario.hydrocarbon_settings,
          d.hydrocarbon_settings
        )
    );

    return {
      ...documentStyle,
      title: documentStyle?.title || null,
    };
  }

  return style;
};

export const getDocumentTitle = (
  {
    document_shorthand,
    document,
    long_url,
    type,
    media_2 = null,
    depth_from = null,
    depth_to = null,
    pathways = null,
  },
  use_shorthand = false
) => {
  const documentTitle =
    (use_shorthand ? document_shorthand : null) ||
    document ||
    document_shorthand ||
    long_url;

  if (!type && !media_2 && !depth_from && !depth_to) {
    return documentTitle;
  }

  let docType = type ? type : '';

  if (docType === 'management-limits') {
    docType = 'Management Limits';
  } else {
    docType = docType.toUpperCase();
  }

  return (
    documentTitle +
    (docType ? ` ${docType}` : '') +
    (media_2 ? ', ' + getFormattedMediaType(media_2) : '') +
    (depth_from || depth_to
      ? ' ' +
        getFormattedSoilDepth({
          depth_from,
          depth_to,
        })
      : '') +
    (pathways ? ' ' + 'for ' + getFormattedPathway(pathways) : '')
  );
};

export const getEILProperties = (guideline) => {
  if (!guideline.ph_value && !guideline.cec_value && !guideline.clay_content) {
    return;
  }

  let text = ' with';
  if (guideline.ph_value) {
    text += ' pH of ' + guideline.ph_value;
  }

  if (guideline.cec_value) {
    text += ' CEC of ' + guideline.cec_value;
  }

  if (guideline.clay_content) {
    text += ' % clay of ' + guideline.clay_content;
  }

  return text;
};

/**
 * Formatting
 */

const getTitle = (title, country = null, document = null) => {
  const fallback = (country ? title[country] : null) || title.default;
  if (document && title[document.short_url]) {
    return title[document.short_url];
  }
  return fallback;
};

/**
 * @deprecated use useScenarioStyling composable -> getScenarioTitle
 */
export const getFormattedTitle = (
  scenario,
  document,
  showBasis = true,
  criteriaType,
  factor = 1,
  country = null,
  showFactor = false,
  options = null,
  hydrocarbonSettings = null
) => {
  if (criteriaType == 'criteria') {
    if (scenario.is_background) {
      return `Background: ${scenario.title}`;
    }
    return scenario.title;
  }

  if (scenario.full_title) {
    return scenario.full_title || '(all)';
  }

  const basis = showBasis ? scenario?.basis : '';
  const landuse = scenario?.landuse;

  const withFactor =
    showFactor && factor && factor != 1 ? ' with factor ' + factor : '';

  const scenarioType = formattedTypes[scenario?.type] || null;
  const basisType = basisTypes[basis] || '';

  const withType = scenarioType
    ? ' -> ' + (scenarioType ? getTitle(scenarioType, country) : scenario.type)
    : '';
  const produceConsumptionPercentage =
    options?.produce_consumption_percentage || null;
  const withProduceConsumptionPercentage = produceConsumptionPercentage
    ? ` (${produceConsumptionPercentage} Produce)`
    : '';

  const withText = withType + withFactor + withProduceConsumptionPercentage;

  const basisTitle = showBasis ? getTitle(basisType, country) + ', ' : '';

  const getHydrocarbonText = () => {
    if (!hydrocarbonSettings) {
      return '';
    }

    const { soil_type, depth_from, depth_to, pathway } = hydrocarbonSettings;

    let hydrocarbonText = '';

    if (soil_type) {
      hydrocarbonText += ` ${getFormattedMediaType(soil_type)}`;
    }

    if (depth_from || depth_to) {
      hydrocarbonText += ` ${getFormattedSoilDepth({
        depth_from,
        depth_to,
      })}`;
    }

    if (pathway) {
      hydrocarbonText += ` ${getFormattedPathway(pathway)}`;
    }

    return hydrocarbonText;
  };

  if (!landuse || (!landuse && basisType != null)) {
    return basisTitle + withText + getHydrocarbonText();
  }

  const landuseType = getFormattedLanduse(landuse, document, country);

  if (!basisType && !landuseType) {
    return (
      (showBasis ? basis + ', ' : '') +
      landuse +
      withText +
      getHydrocarbonText()
    );
  }

  if (!basisType && landuseType) {
    return landuseType + withText + getHydrocarbonText();
  }

  if (!landuseType) {
    return basisTitle + landuse + withText + getHydrocarbonText();
  }

  return basisTitle + landuseType + withText + getHydrocarbonText();
};

export const getFormattedType = (type) => {
  if (!type) {
    return 'unset';
  }

  const title = formattedTypes[type];
  return title ? getTitle(title) : type;
};

export const getFormattedPathway = (pathway) => {
  if (!pathway) {
    return 'Not Applicable';
  }

  const foundPathway = formattedPathways[pathway];
  if (!foundPathway) {
    return pathway;
  }

  return getTitle(foundPathway);
};

export const getFormattedHQType = (hqType) => {
  if (!hqType) {
    return 'Not Set';
  }

  const foundType = formattedHQTypes[hqType];

  if (!foundType) {
    return hqType;
  }

  return getTitle(foundType);
};

export const getFormattedLanduse = (landuse, document, country) => {
  const formattedLanduse = landuseTypes[landuse];

  if (!formattedLanduse) {
    return landuse;
  }

  return getTitle(formattedLanduse, country, document);
};

export const getFormattedBasis = (basis) => {
  const formattedBasis = basisTypes[basis];
  if (!formattedBasis) {
    return basis;
  }

  return getTitle(formattedBasis);
};

export const getFormattedMediaType = (media) => {
  if (!media) {
    return;
  }

  const localMedia = media.toString();
  return (
    mediaTypes[media] ||
    localMedia.charAt(0).toUpperCase() + localMedia.slice(1)
  );
};

export const getFormattedSoilDepth = ({ depth_from, depth_to }) => {
  if (!depth_from && !depth_to) {
    return 'Unset';
  }

  if (depth_from && !depth_to) {
    return `>${depth_from}m`;
  }

  if (!depth_from && depth_to) {
    return `<${depth_to}m`;
  }

  return `${depth_from}m - ${depth_to}m`;
};

export const getFullScenarioText = (
  scenario,
  document,
  country,
  withBasis = false
) => {
  if (scenario.full_title) {
    return scenario.full_title || '(all)';
  }

  const landuse = getFormattedLanduse(scenario.landuse, document, country);
  const type = scenario.type ? getFormattedType(scenario.type) : null;

  if (!landuse && !type) {
    return getFormattedBasis(scenario.basis);
  }

  const basis = withBasis ? getFormattedBasis(scenario.basis) + ', ' : '';

  if (!landuse) {
    return basis + type;
  }

  return basis + landuse + (type ? ' -> ' + type : '');
};

export const getScenarioItemGuideline = (
  chemical,
  scenario,
  hydrocarbonLookup = null
) => {
  // priortize all pathways in table export
  const itemGuideline = [...(chemical?.item_guidelines || [])]
    .sort(
      (a, b) => (b.pathways == 'all_pathways') - (a.pathways == 'all_pathways')
    )
    .find(
      (g) =>
        g.scenario_id == scenario.id &&
        (hydrocarbonLookup
          ? (hydrocarbonLookup?.pathway
              ? g.pathways == hydrocarbonLookup.pathway
              : true) &&
            g.media_2 == hydrocarbonLookup.soil_type &&
            g.depth_from == hydrocarbonLookup.depth_from &&
            g.depth_to == hydrocarbonLookup.depth_to
          : true)
    );

  if (!itemGuideline) {
    return null;
  }

  return itemGuideline;
};

export const getScenarioValue = (
  chemical,
  scenario,
  formatAsScientific = false,
  compositeOf = null,
  compositeGuidelineRounding = null,
  hydrocarbonLookup = null,
  unitConversions = []
) => {
  if (scenario.criteria_type == CriteriaType.Criteria) {
    const criteriaAnalyte = (chemical?.criteria_analytes || []).find(
      (v) => v.set_id == scenario.criteria_set_id
    );

    if (!criteriaAnalyte) {
      return '-';
    }

    let criteriaValues = [];

    criteriaAnalyte.values.forEach((value) => {
      const minimumValue = convertValueByUnits(
        value.minimum_value,
        criteriaAnalyte.units,
        chemical.units,
        chemical.unit_multiplier,
        unitConversions
      );

      const maximumValue = convertValueByUnits(
        value.maximum_value,
        criteriaAnalyte.units,
        chemical.units,
        chemical.unit_multiplier,
        unitConversions
      );

      criteriaValues.push(
        getFormattedValue(minimumValue, formatAsScientific) +
          (maximumValue
            ? ' - ' + getFormattedValue(maximumValue, formatAsScientific)
            : '')
      );
    });

    return criteriaValues.join(', ');
  }

  const itemGuideline = getScenarioItemGuideline(
    chemical,
    scenario,
    hydrocarbonLookup
  );

  if (!itemGuideline) {
    return null;
  }

  return getGuidelineValue(
    itemGuideline,
    chemical,
    formatAsScientific,
    compositeOf,
    compositeGuidelineRounding,
    unitConversions
  );
};

export const getGuidelineCommentIdentifier = (
  chemical,
  scenario,
  hydrocarbonLookup,
  scenarioSet,
  guidelineComments
) => {
  if (scenario.criteria_type !== 'landuse' || guidelineComments.length === 0) {
    return null;
  }

  if (
    hasFooterSettingToggled(
      EditableBlockType.GUIDELINE_NOTE,
      scenarioSet,
      'is_visible'
    )
  ) {
    const itemGuideline = getScenarioItemGuideline(
      chemical,
      scenario,
      hydrocarbonLookup
    );

    if (itemGuideline) {
      const indentifiers = guidelineComments
        .filter((c) => c.guideline_ids.includes(itemGuideline.guideline_id))
        .map((c) => c.identifier);

      return indentifiers;
    }
  }

  return null;
};

export const getOrderOfScenario = (scenario, scenarioGroups, groupId) => {
  const currentGroup = scenarioGroups.find((group) => group.id === groupId);

  const currentScenarioItem =
    currentGroup.group_items.find(
      (gItem) => gItem.scenario_id == scenario.id
    ) || null;

  if (currentScenarioItem === null) {
    return Number.MAX_SAFE_INTEGER;
  }

  return currentScenarioItem.order;
};
